import Layout from '../components/layouts/admin/Layout';
import styled from '@emotion/styled';

const ContentLoader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default function Custom404() {

  return (
    <Layout>
      <ContentLoader>
        <h1>Error 404</h1>
        <p>Page not found.</p>
      </ContentLoader>
    </Layout>
  )
}